import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_BASE_URL } from '../constants';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles?.('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles?.('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export default function PasswordRecovery() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  // States for password reset
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [passwordSuccess, setPasswordSuccess] = React.useState(false);
  const [passwordSuccessMessage, setPasswordSuccessMessage] = React.useState('');

  // States for email request
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [emailSuccess, setEmailSuccess] = React.useState(false);
  const [emailSuccessMessage, setEmailSuccessMessage] = React.useState('');

  const handlePasswordReset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setPasswordError(true);
      setPasswordErrorMessage('Passwords do not match');
      return;
    }

    if (newPassword.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/v1/auth/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          newPassword
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setPasswordSuccess(true);
        setPasswordSuccessMessage(data.message || 'Password successfully reset');
        // Redirect to signin page after 3 seconds
        setTimeout(() => {
          navigate('/signin');
        }, 3000);
      } else {
        setPasswordError(true);
        setPasswordErrorMessage(data.message || 'Something went wrong');
      }
    } catch (error) {
      setPasswordError(true);
      setPasswordErrorMessage('Network error occurred');
    }
  };

  const handleEmailRequest = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/v1/auth/password/recovery`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setEmailSuccess(true);
        setEmailSuccessMessage(data.message || 'Recovery instructions sent to your email');
      } else {
        setEmailError(true);
        setEmailErrorMessage(data.message || 'Something went wrong');
      }
    } catch (error) {
      setEmailError(true);
      setEmailErrorMessage('Network error occurred');
    }
  };

  const renderEmailForm = () => (
    <Box
      component="form"
      onSubmit={handleEmailRequest}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
      }}
    >
      {emailSuccess ? (
        <Alert severity="success" sx={{ mb: 2 }}>
          {emailSuccessMessage}
        </Alert>
      ) : (
        <>
          <FormControl>
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <TextField
              error={emailError}
              helperText={emailErrorMessage}
              name="email"
              placeholder="name@example.com"
              type="email"
              id="email"
              autoComplete="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
              color={emailError ? 'error' : 'primary'}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
                setEmailErrorMessage('');
              }}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
          >
            Send Reset Instructions
          </Button>
        </>
      )}
    </Box>
  );

  const renderPasswordForm = () => (
    <Box
      component="form"
      onSubmit={handlePasswordReset}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
      }}
    >
      {passwordSuccess ? (
        <Alert severity="success" sx={{ mb: 2 }}>
          {passwordSuccessMessage}
        </Alert>
      ) : (
        <>
          <FormControl>
            <FormLabel htmlFor="newPassword">New Password</FormLabel>
            <TextField
              error={passwordError}
              helperText={passwordErrorMessage}
              name="newPassword"
              placeholder="••••••"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              autoFocus
              required
              fullWidth
              variant="outlined"
              color={passwordError ? 'error' : 'primary'}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordError(false);
                setPasswordErrorMessage('');
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
            <TextField
              error={passwordError}
              name="confirmPassword"
              placeholder="••••••"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              required
              fullWidth
              variant="outlined"
              color={passwordError ? 'error' : 'primary'}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setPasswordError(false);
                setPasswordErrorMessage('');
              }}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
          >
            Reset Password
          </Button>
        </>
      )}
    </Box>
  );

  return (
    <div>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          1browser
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            {token ? 'Reset Password' : 'Forgot Password'}
          </Typography>
          {token ? renderPasswordForm() : renderEmailForm()}
        </Card>
      </SignInContainer>
    </div>
  );
} 