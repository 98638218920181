export const SuccessHtmlPage = `<html lang="en" class="">

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
  <meta charset="UTF-8" />
  <meta name="robots" content="noindex" />
  <meta name="description"
    content="Antidetect Browser GoLogin - Chromium based anonymous browser helps you to surf the web anonymously without being banned and recognized." />
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&amp;display=swap"
    rel="stylesheet">
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&amp;display=swap"
    rel="stylesheet">
  <script>
  document.addEventListener('DOMContentLoaded', (event) => {
    console.log('redirect');
      setTimeout(() => {
          goToDesktopApp();
      }, 5000);
  });
  </script>
  <script src="https://browser.sentry-cdn.com/8.2.1/bundle.replay.min.js"
    integrity="sha384-fJJhXcJ20du4ixiPAjI+NfUOjNUoO/mCCwBWH5mS9OScu2V+KzW+RbjpdzVx0agl"
    crossorigin="anonymous"></script>
  <!-- Google Tag Manager -->
  <script>(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-PCVDMBT');
  </script>
  <!-- End Google Tag Manager -->
  <style>
    body.theme-dark {
      --FFFFFF-new-pricing: var(--FFFFFF);
      --00A987-new-pricing: var(--00A987);
      --DEF7E9-new-pricing: var(--DEF7E9);
      --81818A-new-pricing: var(--81818A);
      --00997A-new-pricing: var(--00997A);
      --222222-new-pricing: var(--222222);
      --222222: #cfd1e2;
      --00A987: #099e8b;
      --00997A: #0ab19c;
      --FFFFFF: #1e1f2b;
      --DEF7E9: #2c2d3d;
      --81818A: #858699;
      --F9F9F9: #191A23;
    }
  </style>
  <style>
    html,
    body {
      width: 100%;
      height: 100%;
    }
  </style>
  <style data-emotion="css-global">
    * {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
    }
  </style>
  <style data-emotion="css-global">
    html,
    body {
      width: 100%;
      height: 100%;
      overflow: hidden;
      color: var(--222222);
      background-color: var(--FFFFFF);
    }
  </style>
  <style data-emotion="css-global">
    #root {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  </style>
  <style data-emotion="css-global">
    body {
      margin: 0;
      padding: 0;
    }
  </style>
  <style data-emotion="css-global">
    .ant-tabs-nav .ant-tabs-tab {
      margin: 0 8px 0 0;
    }
  </style>
  <style data-emotion="css-global">
    .ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
      padding: 8px;
    }
  </style>
  <style data-emotion="css">
    .css-tsqdqn {
      width: 100%;
      height: 100%;
      background-color: var(--F9F9F9);
      height: auto;
      min-height: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  </style>
  <style data-emotion="css">
    .css-14wvi39 {
      background: var(--FFFFFF);
      height: 100%;
      font-size: 40px;
      padding-top: 30px;
      margin-bottom: 30px;
      text-align: center;
      color: var(--222222);
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  </style>
  <style data-emotion="css">
    .css-pabt4m {
      position: relative;
      height: 100%;
      padding: 128px 61px 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      row-gap: 32px;
    }
  </style>
  <style data-emotion="css">
    .css-1crnu8s {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: var(--36363D-new-pricing);
      margin: 0;
    }
  </style>
  <style data-emotion="css">
    .css-1ge9p4h {
      margin: 0;
      max-height: 40px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: var(--36363D-new-pricing);
      -webkit-letter-spacing: 0.25px;
      -moz-letter-spacing: 0.25px;
      -ms-letter-spacing: 0.25px;
      letter-spacing: 0.25px;
      margin-top: 16px;
      color: var(--81818A);
      line-height: 20px;
      max-height: unset;
    }
  </style>
  <style data-emotion="css">
    .css-1wophpj {
      display: block;
      margin: 32px auto 0;
      outline: none;
      padding: 11px 20px;
      min-width: 200px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      -webkit-letter-spacing: 0.15px;
      -moz-letter-spacing: 0.15px;
      -ms-letter-spacing: 0.15px;
      letter-spacing: 0.15px;
      color: var(--FFFFFF-new-pricing);
      background: var(--00A987-new-pricing);
      border-radius: 26px;
      border: 1px solid var(--00A987-new-pricing);
      cursor: pointer;
      min-width: 228px;
      margin: 0;
      padding: 8px 30px;
      border: none;
      border-radius: 20px;
      font-family: "Roboto Flex", sans-serif;
      font-size: 16px;
      line-height: 24px;
      -webkit-letter-spacing: 0.44px;
      -moz-letter-spacing: 0.44px;
      -ms-letter-spacing: 0.44px;
      letter-spacing: 0.44px;
    }
  </style>
  <style data-emotion="css">
    .css-1wophpj:hover {
      background: var(--00997A-new-pricing);
    }
  </style>
  <style data-emotion="css">
    .css-1wophpj:disabled {
      color: var(--81818A-new-pricing);
      background: var(--FFFFFF-new-pricing);
      border: 1px solid var(--D2D2D5-new-pricing);
    }
  </style>
</head>

<body class="theme-dark">
  <!-- Google Tag Manager (noscript) -->
  <noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PCVDMBT" height="0" width="0"
      style="display:none;visibility:hidden"></iframe>
  </noscript>
  <!-- End Google Tag Manager (noscript) -->
  <div id="root">
    <div class="css-tsqdqn">
      <div class="css-pabt4m">
        <svg id="svg-status-icon" width="64" height="64" viewBox="0 0 64 64" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="32" cy="32" r="32" fill="var(--DEF7E9-new-pricing)"></circle>
          <circle cx="32" cy="32" r="24" fill="var(--00A987-new-pricing)"></circle>
          <path
            d="M28.5 40C28.102 40 27.72 39.842 27.439 39.561L21.086 33.207C20.696 32.816 20.696 32.183 21.086 31.793L21.793 31.086C22.184 30.695 22.817 30.695 23.207 31.086L28.5 36.379L40.793 24.086C41.184 23.695 41.817 23.695 42.207 24.086L42.914 24.793C43.305 25.184 43.305 25.817 42.914 26.207L29.561 39.561C29.28 39.842 28.898 40 28.5 40Z"
            fill="var(--FFFFFF-new-pricing)"></path>
        </svg>
        <div>
          <h2 id="modal-title" class="css-1crnu8s">Payment success!</h2>
          <h3 id="modal-description" class="css-1ge9p4h">Within 5 seconds you will be redirected to settings.<br>If you are not redirected, press the button</h3>
        </div>
        <button class="css-1wophpj" onclick="window.location.href='/settings'">Go to settings</button>
      </div>
    </div>
  </div>
</body>

</html>`
