import { useEffect, useRef } from 'react';

export const HtmlContent = ({ html }: { html: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/settings';
    }, 5000);
  }, []);

  return <div ref={containerRef} dangerouslySetInnerHTML={{ __html: html }} />;
};