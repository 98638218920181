import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './sign-in/SignIn';
import SignUp from './sign-up/SignUp';
import { OverviewPage } from './settings/overview';
import { SuccessHtmlPage } from './pricing/page-payment-success';
import { FailedHtmlPage } from './pricing/page-payment-failed';
import { HtmlContent } from './components/payment-redirect-pages/success';
import ErrorPopup from './components/error-popup';
import PasswordRecovery from './password-recovery/PasswordRecovery';

// const basename = process.env.NODE_ENV === 'production' ? '/account' : '/';

function App() {
  const [error, setError] = useState<string | null>(null);
  React.useEffect(() => {
    console.log('cookies:', document.cookie);
  }, []);

  window.onerror = (message, source, lineno, colno, error) => {
    setError(message as string);
  } 

  const handleErrorClose = () => {
    setError(null);
  };

  return (
      <Router>
        <div>
          {error && <ErrorPopup message={error} onClose={handleErrorClose} />}
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/settings" element={<OverviewPage />} />
              <Route path="/checkout/success" element={<HtmlContent html={SuccessHtmlPage} />} />
              <Route path="/checkout/failed" element={<HtmlContent html={FailedHtmlPage} />} />
              <Route path="/logout" element={<SignIn />} />
              <Route path="/password-recovery" element={<PasswordRecovery />} />
            </Routes>
        </div>
      </Router>
  );
}

export default App;
