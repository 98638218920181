import React, { useEffect } from 'react';
import './error-popup.css';

interface ErrorPopupProps {
  message: string;
  onClose: () => void;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000); // Close after 3 seconds
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [onClose]);

  return (
    <div className="error-popup">
      <div className="error-popup-content">
        <span className="error-popup-message">{message}</span>
      </div>
    </div>
  );
};

export default ErrorPopup;