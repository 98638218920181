import { API_BASE_URL } from "../constants";

interface ApiRequestConfig extends RequestInit {
  method?: string;
}

export const makeApiRequest = async (
  uri: string,
  config: ApiRequestConfig = {}
): Promise<any> => {
  const accessToken = localStorage.getItem('accessToken');
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };
  const url = `${API_BASE_URL}${uri}`;
  console.log('config', config);
  let response = await fetch(url, config);
  console.log('response', response);
  if (response.status === 401) {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      const newAccessToken = await refreshAccessToken(refreshToken);
      console.log('newAccessToken', newAccessToken);
      if (newAccessToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${newAccessToken}`,
        };

        response = await fetch(url, config);
      }
    }
  }

  if (!response.ok) {
    throw new Error(`API request failed: ${response.statusText}`);
  }

  return response.json();
}

async function refreshAccessToken(refreshToken: string): Promise<string | null> {
  try {
    const response = await fetch(`${API_BASE_URL}/v1/auth/access-token`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshToken}`,
      }
    });

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    localStorage.setItem('accessToken', data.token);
    return data.token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    return null;
  }
}
