import { Button, message, Modal, Tooltip } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChangeEmailComponent from './email';
import PasswordChangeForm from './PasswordChangeForm';
import {
  AccountEmail,
  AlignedButton,
  OverviewGrid,
  OverviewRowTitle,
} from './styles';

import { PageTitle } from '../styles';
import { API_BASE_URL } from '../../constants';
import { makeApiRequest } from '../../utils/makeApiRequest';

export const OverviewPage: FC = () => {
  const { t: translation, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);
  const [isEmailChangeModalOpened, setIsEmailChangeModalOpened] = useState<boolean>(false);
  const [isPasswordChangeModalOpened, setIsPasswordChangeModalOpened] = useState<boolean>(false);
  const [isPaymentModalOpened, setIsPaymentModalOpened] = useState<boolean>(false);
  const [is2faModalOpened, setIs2faModalOpened] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});

  // const theme = useCurrentTheme();
  useEffect(() => {
    makeApiRequest(`/v1/user`, {
      method: 'GET',
  }).then((res) => {setUser(res); console.log('res', res)});
  }, []);

  useEffect(() => {
    const locale = localStorage.getItem('@gologin:selected_language');
    setLanguage(locale || i18n.language);
  }, []);

  const confirmEmail = (): void => {
    makeApiRequest(`/v1/auth/email/verify`, {
      method: 'POST',
    }).then((res) => {
      console.log('res', res);
      message.success(translation('personalArea.overview.AlertEmailConfirm'));
    });
  };

  const changeLanguage = (code: string): void => {
    i18n.changeLanguage(code).then(() => {
      setLanguage(i18n.language);
      moment.locale(i18n.language);
      localStorage.setItem('@gologin:selected_language', i18n.language);
      const translationData = {
        language: i18n.language,
        closeAppTitle: translation('closeApp.title'),
        closeAppMessage: translation('closeApp.message'),
        closeAppBtnClose: translation('closeApp.btnClose'),
        closeAppBtnCancel: translation('closeApp.btnCancel'),
      };

    });
  };

  const openPricing = () => {
    setIsPaymentModalOpened(true);
  }

  const onEmailChanged = (newEmail: string): void => {
    setIsEmailChangeModalOpened(false);
  };

  const renderEmail = (emailToRender: string): JSX.Element|string => {
    const EMAIL_LENGTH = 22;

    if (emailToRender.length > EMAIL_LENGTH) {
      return (
        <Tooltip title={emailToRender}>
          {emailToRender.slice(0, EMAIL_LENGTH)}
          ...
        </Tooltip>
      );
    }

    return emailToRender;
  };

  const createPaymentSession = async ({period, plan}: {period: string, plan: string}) => {
    const createCheckoutSession = await makeApiRequest(`/v1/payment/stripe/payment-session`, {
      method: 'POST',
      body: JSON.stringify({
        period,
        plan,
      }),
    });

    console.log('createCheckoutSession', createCheckoutSession);
    if (createCheckoutSession.url) {
      window.location.href = createCheckoutSession.url;
    }
  }

  const createBillingPortalSession = async () => {
    const createCheckoutSession = await makeApiRequest('/v1/payment/stripe/customer-portal');

    if (createCheckoutSession.url) {
      window.location.href = createCheckoutSession.url;
    }
  }

  const getPaymentModal = (): JSX.Element | null => {
    return (
      <div>
        <Button onClick={() => createPaymentSession({period: 'month', plan: '6724d0a7fdcb2bc43d1a4214'})} style={{marginRight: 20}}>$19/month</Button>
        <Button onClick={() => createPaymentSession({period: 'year', plan: '6724d0a7fdcb2bc43d1a4214'})}>$160/year (30% discount) </Button>
      </div>
    )
  }

  return (
    <div style={{ margin: '40px'}}>
      <PageTitle>
        {translation('personalArea.overview.title')}
      </PageTitle>
      <OverviewGrid>
        <OverviewRowTitle>
          {translation('personalArea.overview.subTitleEmail')}
        </OverviewRowTitle>
        <div style={{ display: 'flex' }}>
          {renderEmail(user.email || '')}
          <AccountEmail>
            {!user.isEmailConfirmed && (
              <Tooltip title={translation('personalArea.overview.TooltipConfirmEmail')}>
              </Tooltip>
            )}
            <Tooltip title={translation('personalArea.overview.editBtnText')}>
            </Tooltip>
          </AccountEmail>
        </div>
        {!user.isEmailConfirmed ? (
          <AlignedButton
            type='default'
            onClick={confirmEmail}
          >
            {translation('personalArea.overview.confirmSubmitBtnText')}
          </AlignedButton>
        ) : <div />}
        <OverviewRowTitle>
          {translation('base.password')}
        </OverviewRowTitle>
        <div />
        <AlignedButton
          type='default'
          onClick={(): void => setIsPasswordChangeModalOpened(true)}
        >
          {translation('changePassInSettings.changePassword')}
        </AlignedButton>
        <OverviewRowTitle>
          Two Factor Authentication
        </OverviewRowTitle>
        <div />
        <AlignedButton
          type='default'
          onClick={(): void => setIs2faModalOpened(true)}
        >
          Enable
        </AlignedButton>
        <OverviewRowTitle>
          Plan
        </OverviewRowTitle>
        <div style={{ display: 'flex' }}>
          {renderEmail(user?.planName || '')}
          
        </div>
        <AlignedButton
            type='default'
            onClick={user?.planName === 'Free' ? openPricing : createBillingPortalSession}
        >
          {user?.planName === 'Free' ? 'Change Plan' : 'Manage Subscription'}
        </AlignedButton>
        <form action={`${API_BASE_URL}/v1/auth/logout`} method="POST">
          <AlignedButton
            type='default'
            style={{ backgroundColor: 'red', color: 'white' }}
            htmlType="submit"
            onClick={() => {
              console.log('logout');
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
            }}
          >
            Log out
          </AlignedButton>
        </form>
      </OverviewGrid>
      <Modal
        visible={isEmailChangeModalOpened}
        onCancel={(): void => setIsEmailChangeModalOpened(false)}
        title={translation('personalArea.changeEmailModal.title')}
        footer={null}
      >
        <ChangeEmailComponent
          initialEmail={user.email}
          onConfirm={onEmailChanged}
        />
      </Modal>
      <Modal
        visible={isPaymentModalOpened}
        onCancel={(): void => setIsPaymentModalOpened(false)}
        title='Unlimited access'
        footer={null}
      >
        {getPaymentModal()}
      </Modal>
      <Modal
        visible={isPasswordChangeModalOpened}
        onCancel={(): void => setIsPasswordChangeModalOpened(false)}
        title={translation('changePassInSettings.changePassword')}
        footer={null}
      >
        <PasswordChangeForm
          onSubmit={(): void => setIsPasswordChangeModalOpened(false)}
        />
      </Modal>
      <Modal
        visible={is2faModalOpened}
        onCancel={(): void => setIs2faModalOpened(false)}
        title={translation('changePassInSettings.changePassword')}
        footer={null}
      >
        <PasswordChangeForm
          onSubmit={(): void => setIsPasswordChangeModalOpened(false)}
        />
      </Modal>
    </div>
  );
};
